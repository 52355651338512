import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "react-native-for-ic-apps"
    }}><a parentName="h1" {...{
        "href": "#react-native-for-ic-apps"
      }}>{`React Native for IC Apps`}</a></h1>
    <h2 {...{
      "id": "introduction"
    }}><a parentName="h2" {...{
        "href": "#introduction"
      }}>{`Introduction`}</a></h2>
    <p>{`This tutorial will walk you through the process of building a React Native app for the Internet Computer using Expo and Internet Identity. It has been a while coming, due to the fact that `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{` has been using `}<inlineCode parentName="p">{`webassembly`}</inlineCode>{` modules for verifying BLS signatures, but we now have a solution that works with React Native.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: this depends on a version of agent-js that has not been released as of publishing. To get started, you will need to download this zip file of the `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{`, `}<inlineCode parentName="p">{`@dfinity/bls-verify`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@noble/curves`}</inlineCode>{` packages and install it locally in your project. Once the new version is released, you can remove this dependency and install the packages directly from npm.`}</p>
    </blockquote>
    <a href="/react-native-deps.zip" download>Download Dependencies</a>
    <p>{`Then, you can install the dependencies in your project:`}</p>
    <pre {...{
      "className": "language-bash"
    }}><code parentName="pre" {...{
        "className": "language-bash"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`npm`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`install`}</span>{` ./react-native-deps/dfinity-agent-0.20.2.tgz `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
./react-native-deps/dfinity-bls-verify-0.20.2.tgz `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
./react-native-deps/noble-curves-1.2.0.tgz
`}</code></pre>
    <h1 {...{
      "id": "getting-started"
    }}><a parentName="h1" {...{
        "href": "#getting-started"
      }}>{`Getting Started`}</a></h1>
    <h2 {...{
      "id": "prerequisites"
    }}><a parentName="h2" {...{
        "href": "#prerequisites"
      }}>{`Prerequisites`}</a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/en/"
        }}>{`Node.js`}</a>{` (version 18 or higher)`}</li>
      <li parentName="ul">{`Xcode (for iOS development)`}</li>
      <li parentName="ul">{`Android Studio (for Android development)`}</li>
      <li parentName="ul">{`(recommended) Apple Developer Account (for iOS development)`}</li>
    </ul>
    <h2 {...{
      "id": "expo-quickstart"
    }}><a parentName="h2" {...{
        "href": "#expo-quickstart"
      }}>{`Expo Quickstart`}</a></h2>
    <p>{`First, you can run the following command to create your app. I'll be naming the project `}<inlineCode parentName="p">{`ic-expo`}</inlineCode>{`, but you can name it whatever you want.`}</p>
    <pre {...{
      "className": "language-bash"
    }}><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx create-expo-app ic-expo
`}</code></pre>
    <p>{`Then, `}<inlineCode parentName="p">{`cd`}</inlineCode>{` into the project. At this point, if you are set up with your preferred development environment, you can run `}<inlineCode parentName="p">{`expo run ios`}</inlineCode>{` or `}<inlineCode parentName="p">{`expo run android`}</inlineCode>{` to start the app in the simulator. If you are not set up, you can follow the instructions in the `}<a parentName="p" {...{
        "href": "https://docs.expo.dev/get-started/installation/"
      }}>{`Expo documentation`}</a>{` to get set up.`}</p>
    <h2 {...{
      "id": "installing-dependencies"
    }}><a parentName="h2" {...{
        "href": "#installing-dependencies"
      }}>{`Installing Dependencies`}</a></h2>
    <p>{`Next, we need to install the dependencies for our app. We will be using `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{` to interact with the Internet Computer, and there are several other necessary dependencies we'll also need to install.`}</p>
    <pre {...{
      "className": "language-bash"
    }}><code parentName="pre" {...{
        "className": "language-bash"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`npm`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`install`}</span>{` --save `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
@dfinity/agent `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
@dfinity/identity `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
@dfinity/principal `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
@dfinity/candid `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
@react-native-async-storage/async-storage `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
amcl-js `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{`
buffer `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`\\`}</span>{` 
expo-secure-store
`}</code></pre>
    <h2 {...{
      "id": "setting-up-internet-identity"
    }}><a parentName="h2" {...{
        "href": "#setting-up-internet-identity"
      }}>{`Setting up Internet Identity`}</a></h2>
    <p>{`We will be using Internet Identity to authenticate users. To pull this off, we need to host a website that will process the requests from our app and return a delegation.`}</p>
    <p>{`I recommend simply dropping the `}<a href="/ii_integration.zip" download>{`ii_integration website`}</a>{` into your project in `}<inlineCode parentName="p">{`src/ii_integration`}</inlineCode>{`. This is a simple website that will handle the requests from our app and return a delegation. Add a `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{` file to the root of your project for the `}<inlineCode parentName="p">{`ii_integration`}</inlineCode>{` site, as well as configs for a `}<inlineCode parentName="p">{`whoami`}</inlineCode>{` canister and `}<inlineCode parentName="p">{`internet-identity`}</inlineCode></p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"internet-identity"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"pull"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"id"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"rdmx6-jaaaa-aaaaa-aaadq-cai"`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"whoami"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"pull"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"id"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ivcos-eqaaa-aaaab-qablq-cai"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"declarations"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
                `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"node_compatibility"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span>{`
            `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"ii_integration"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"assets"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"source"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`
                `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/ii_integration/assets"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
                `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/ii_integration/dist"`}</span>{`
            `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"output_env_file"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`".env"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"version"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <h2 {...{
      "id": "how-it-works"
    }}><a parentName="h2" {...{
        "href": "#how-it-works"
      }}>{`How it works`}</a></h2>
    <p>{`The `}<inlineCode parentName="p">{`ii_integration`}</inlineCode>{` site uses `}<inlineCode parentName="p">{`@dfinity/auth-client`}</inlineCode>{` to integrate with Internet Identity. When the page is loaded, it looks for a `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` and a `}<inlineCode parentName="p">{`pubkey`}</inlineCode>{` in the URL parameters. If it finds these, it will authenticate with Internet Identity and then generate a deep link that will return the delegation to the app. The app will then use this delegation to authenticate calls to the Internet Computer. `}</p>
    <p>{`The key tricks here are: `}</p>
    <h3 {...{
      "id": "1-create-the-auth-client-with-a-base-key-from-the-public-key"
    }}><a parentName="h3" {...{
        "href": "#1-create-the-auth-client-with-a-base-key-from-the-public-key"
      }}>{`1. Create the auth-client with a base key from the public key`}</a></h3>
    <p>{`The IncompleteEd25519KeyIdentity class is a workaround, since the auth-client expects a full keypair. We can get around this by creating an identity with the public key, and then passing it to the auth-client. In the future I'll change the support in the library so the setup is simpler.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Represent the public key as an identity`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`class`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`IncompleteEd25519KeyIdentity`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`extends`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`SignIdentity`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`constructor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`publicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`super`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`this`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_publicKey`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` publicKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getPublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`this`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`_publicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` identity `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`IncompleteEd25519KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Ed25519PublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromDer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`fromHex`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`pubKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` authClient `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`AuthClient`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`create`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <h3 {...{
      "id": "2-generate-a-deep-link-to-return-the-delegation-to-the-app"
    }}><a parentName="h3" {...{
        "href": "#2-generate-a-deep-link-to-return-the-delegation-to-the-app"
      }}>{`2. Generate a deep link to return the delegation to the app`}</a></h3>
    <p>{`Once the user has authenticated, we can get the delegation from the auth-client and then generate a deep link that will return the delegation to the app. In this case, the identity is abstracted inside of the the `}<inlineCode parentName="p">{`ii-login-button`}</inlineCode>{` web component, but the logic is very similar with `}<inlineCode parentName="p">{`@dfinity/auth-client`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`onSuccess`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` loginButton `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`document`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`querySelector`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"ii-login-button"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` delegationIdentity `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` loginButton`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`identity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`var`}</span>{` delegationString `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`stringify`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
        delegationIdentity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`getDelegation`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` encodedDelegation `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`encodeURIComponent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`delegationString`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` url `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`redirectUri`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`/redirect?delegation=`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`encodedDelegation`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`Redirecting to `}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`url`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`//   render button to press when we're done`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` button `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`document`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createElement`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"button"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    button`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`innerText`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Continue"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    button`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"click"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`open`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`url`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"_self"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`document`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`body`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`appendChild`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`button`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <h2 {...{
      "id": "setting-up-the-app"
    }}><a parentName="h2" {...{
        "href": "#setting-up-the-app"
      }}>{`Setting up the app`}</a></h2>
    <p>{`The app itself will have a very simple structure, so I'll gloss over that a little bit. The App renders a `}<inlineCode parentName="p">{`LoggedIn`}</inlineCode>{` view and a `}<inlineCode parentName="p">{`LoggedOut`}</inlineCode>{` view, and the `}<inlineCode parentName="p">{`LoggedIn`}</inlineCode>{` view will render the `}<inlineCode parentName="p">{`WhoAmI`}</inlineCode>{` component to display the user's principal, while the `}<inlineCode parentName="p">{`LoggedOut`}</inlineCode>{` view will render the `}<inlineCode parentName="p">{`Pressable`}</inlineCode>{` component to allow the user to log in.`}</p>
    <p>{`The more important logic is contained in the `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` hook, which will handle the authentication logic. It will generate the url to redirect to the `}<inlineCode parentName="p">{`ii_integration`}</inlineCode>{` site, and then it will handle the deep link when the delegation is returned. `}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/app/hooks/useAuth.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useState`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` useEffect `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"react"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` toHex `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/agent"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Ed25519KeyIdentity`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`DelegationChain`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`DelegationIdentity`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  isDelegationValid`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/identity"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="span" {...{
            "className": "token keyword module"
          }}>{`as`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`WebBrowser`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"expo-web-browser"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useURL `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"expo-linking"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`AsyncStorage`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@react-native-async-storage/async-storage"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="span" {...{
            "className": "token keyword module"
          }}>{`as`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`SecureStore`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"expo-secure-store"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`save`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`key`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` value`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`SecureStore`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`setItemAsync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useAuth`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`baseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setBaseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`isReady`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setIsReady`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` url `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useURL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
   * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@type`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`DelegationIdentity `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword null nil"
            }}>{`null`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Dispatch`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`DelegationIdentity `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword null nil"
            }}>{`null`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` state
   */`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setIdentity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useEffect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` storedKey `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`SecureStore`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`getItemAsync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"baseKey"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setBaseKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Ed25519KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` key `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Ed25519KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`generate`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setBaseKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`save`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"baseKey"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`stringify`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` storedDelegation `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`AsyncStorage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`getItem`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"delegation"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedDelegation`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` chain `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DelegationChain`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`parse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedDelegation`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`isDelegationValid`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`chain`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`DelegationIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
            `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Ed25519KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DelegationChain`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`parse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`storedDelegation`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`id`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`SecureStore`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`deleteItemAsync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"delegation"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setIsReady`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useEffect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// If we have an identity, we don't need to do anything`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` search `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`URLSearchParams`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`url`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`split`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"?"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` delegation `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` search`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"delegation"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`delegation`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` chain `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DelegationChain`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
        `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`parse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`decodeURIComponent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`delegation`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`AsyncStorage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`setItem`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"delegation"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`stringify`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`chain`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toJSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
       * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@type`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`DelegationIdentity`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
       */`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DelegationIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromDelegation`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`baseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` chain`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`id`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

      `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`WebBrowser`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`dismissBrowser`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`url`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Function to handle login and update identity based on base key`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`login`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` derKey `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`toHex`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`baseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`getPublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toDer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` url `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`URL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"https://tdpaj-biaaa-aaaab-qaijq-cai.icp0.io/"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Set the redirect uri to the deep link for the app`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// This will be different in a production app`}</span>{`
    url`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`searchParams`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`set`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
      `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"redirect_uri"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`encodeURIComponent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`com.anonymous.ic-expo://expo-development-client`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    url`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`searchParams`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`set`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"pubkey"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` derKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`WebBrowser`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`openBrowserAsync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`url`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toString`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Clear identity on logout`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`logout`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`AsyncStorage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`removeItem`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"delegation"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    baseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    setBaseKey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    isReady`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    login`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    logout`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    
`}</code></pre>
    <p>{`TODO: clean up the canister link for `}<inlineCode parentName="p">{`.env`}</inlineCode>{` based network configs`}</p>
    <h3 {...{
      "id": "setting-up-the-agent"
    }}><a parentName="h3" {...{
        "href": "#setting-up-the-agent"
      }}>{`Setting up the agent`}</a></h3>
    <p>{`Finally, once the delegation identity is ready, we need to pass a couple extra options while setting up the HttpAgent. We need to pass the `}<inlineCode parentName="p">{`identity`}</inlineCode>{` and the `}<inlineCode parentName="p">{`host`}</inlineCode>{` to the agent, like normal, but we will also need to provide `}<inlineCode parentName="p">{`fetchOptions`}</inlineCode>{`, a `}<inlineCode parentName="p">{`blsVerify`}</inlineCode>{` function, and `}<inlineCode parentName="p">{`callOptions`}</inlineCode>{`. This will look like this: `}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    host`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"https://icp-api.io"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    fetchOptions`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        reactNative`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            __nativeResponseType`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"base64"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    blsVerify`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    verifyQuerySignatures`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    callOptions`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        reactNative`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
            textStreaming`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h2 {...{
      "id": "conclusion"
    }}><a parentName="h2" {...{
        "href": "#conclusion"
      }}>{`Conclusion`}</a></h2>
    <p>{`That's it! You should now have a working React Native app that can authenticate with Internet Identity and make calls to the Internet Computer. You can find the full source code for this tutorial at`}<a href="https://github.com/krpeacock/ic-expo-mvp"><a parentName="p" {...{
          "href": "https://github.com/krpeacock/ic-expo-mvp"
        }}>{`https://github.com/krpeacock/ic-expo-mvp`}</a></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      